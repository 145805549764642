import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'url',
  standalone: true
})
export class UrlPipe implements PipeTransform {
  transform(value: string): string {
    return environment.mediaUrl + value;
  }
}
