import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, effect,
  inject, OnDestroy,
  ViewChild
} from '@angular/core';
import {LeafletDirective, LeafletModule} from '@asymmetrik/ngx-leaflet';
import {
  CRS,
  divIcon, DomUtil, icon,
  imageOverlay, latLng, LatLngBounds, latLngBounds,
  LeafletMouseEvent,
  MapOptions,
  Marker,
  marker, Point, point, tileLayer,
} from 'leaflet';
import {IMapLabel, MAP_LABELS} from './map-labels';
import {ModalComponent} from "../../components/modal/item.component";
import {ModalService} from "../../services/modal.service";
import {environment} from '../../../environments/environment';
import {StoreService} from '../../services/store.service';
import {Places} from '../../entities/Places.entity';
import {HttpClientModule} from '@angular/common/http';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {takeUntil} from 'rxjs';

interface IOffset {x: number; y: number}

interface IMarker {
  marker: Marker;
  originalOffset: Point;
  parallaxZOffset: number;
  defaultSize?: {w: number, h: number};
  img?: string;
}

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    LeafletModule,
    ModalComponent,
    HttpClientModule
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class MapComponent implements AfterViewInit, OnDestroy {
  @ViewChild(LeafletDirective) leaflet!: LeafletDirective;

  cdr = inject(ChangeDetectorRef)
  modalService = inject(ModalService)
  store = inject(StoreService)
  destroy$ = inject(TuiDestroyService);

  bounds = latLngBounds(
    latLng(-530, 520),
    latLng(10, -10),
  );

  bgTile = tileLayer(
    `${environment.mediaUrl}/assets/map/{z}/{x}/{y}.png`,
    {
      tileSize: point(256, 256),
      // reuseTiles: true,
      updateWhenIdle: false,
      updateWhenZooming: false,
      maxNativeZoom: 4,
      minNativeZoom: 0,
      keepBuffer: 10,
      bounds: this.bounds,
    });

  baseLayer = {
    'base': this.bgTile
  }

  option: MapOptions = {
    crs: CRS.Simple,
    maxZoom: 4,
    minZoom: 0,
    maxBounds: this.bounds
  };

  locationMarkers: IMarker[] = [];
  labelMarkers: IMarker[] = [];

  isActive = false;
  openElRect!: DOMRect;
  item!: IMapLabel;

  async ngAfterViewInit() {
    this.store.isLoaded.pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      if (this.leaflet && isLoaded) {
        this.map.setView([-261.7, 247.25], 2);

        setTimeout(() => {
          this.store.places().forEach((item) => {
            this.addLocation(item);
          })

          MAP_LABELS.forEach(label => {
            this.addLabel(label);
          });
        })
      }
    })
  }

  ngOnDestroy() {
    if (!this.map) {
      return;
    }

    this.locationMarkers.forEach(marker => {
      this.map.off('move', () => {
        this._onMapMove(marker);
      }, marker.marker);

      this.map.off('zoomstart', () => {
        this._onZoomStart(marker);
      }, marker.marker);
    })
  }


  onClick(event: LeafletMouseEvent) {
    console.log({latLng: event.latlng});
  }

  addLocation(item: Places) {
    if (!item.coordinatesMap) {
      return;
    }
    const newMarker = <IMarker>{
      marker: marker(
        item.coordinatesMap,
        {
          icon: divIcon({
            html: this.markerTemplate(item.number || ''),
            iconSize: [40, 53],
            iconAnchor: [20, 53],
            bgPos: item.coordinatesMap.toPoint(),
          }),
          zIndexOffset: 5
        }
      ),
    };
    this.locationMarkers.push(newMarker);
    newMarker.marker.on('click', (event) => {
      this.modalService.open(
        event.target['_icon'].getBoundingClientRect(),
        item
      );
    })
    this.addMarker(newMarker, [100, 130]);
  }

  addLabel(label: IMapLabel, isAddMarker: boolean = true) {
    this._imageSize(label.img, (size => {
      const newMarker = <IMarker>{
        marker: marker(
          label.latLng,
          {
            icon: this._getIcon(label.img, size, 'map-label'),
            zIndexOffset: 10,
          },
        ),
      };

      newMarker.defaultSize = size;
      newMarker.img = label.img;
      this.labelMarkers.push(newMarker);
      this.addMarker(newMarker, [200, 240]);
    }));
  }

  addMarker(newMarker: IMarker, mainMaxParallax: [number, number]) {
    if (!this.map) {
      return;
    }

    this._setOriginalOffset(newMarker);
    newMarker.parallaxZOffset = Math.floor(Math.random() * (mainMaxParallax[1] - mainMaxParallax[0] + 1)) + mainMaxParallax[0];

    this.map.on('move', () => {
      this._onMapMove(newMarker);
    }, newMarker.marker);

    this.map.on('zoomstart', (e) => {
      this._onZoomStart(newMarker);
    }, newMarker.marker);

    this.cdr.markForCheck();
  }

  get markers(): Marker[] {
    return [...this.locationMarkers.map(item => item.marker), ...this.labelMarkers.map(item => item.marker)];
  }

  markerTemplate(id: number | string) {
    return `<div class="marker">${id}</div>`;
  }

  get map() {
    return this.leaflet?.getMap();
  }

  private _getFactor(): number {
    const defaultFactor = 2;
    const currentZoom = this.map.getZoom() - 3;
    if (currentZoom > 0) {
      return defaultFactor / (currentZoom * 2);
    }

    if (currentZoom < 0) {
      return defaultFactor * Math.abs(currentZoom) * 2;
    }

    return defaultFactor;
  }

  private _imageSize(src: string, callback: (p: { w: number; h: number }) => void) {
    let img = new Image();
    img.src = `${environment.mediaUrl}${src}`;
    img.onload = () => {
      callback({w: img.naturalWidth, h: img.naturalHeight})
    }
  }

  private _onMapMove(marker: IMarker) {
    const offset = this._calculateOffsetFromOrigin(marker);
    if (marker.marker.getIcon() && offset) {
      this._updateIconOffset(marker, offset.centerOffset);
    }
  }

  private _setOriginalOffset(newMarker: IMarker) {
    const anchor = newMarker.marker.options.icon?.options.iconAnchor
      ? point(newMarker.marker.options.icon?.options.iconAnchor)
      : point([0, 0]);

    newMarker.originalOffset = point(-anchor.x, -anchor.y);
  }

  private _onZoomStart(marker: IMarker) {
    // marker.marker.setOpacity(0);
    const tempLatLang = this._calcLatLngFromOffset(marker);
    const origLatLng = marker.marker.getLatLng();
    const origParallaxZOffset = marker.parallaxZOffset;
    marker.parallaxZOffset = 0;
    marker.marker.setLatLng(origLatLng);


    this.map?.once(
      'zoomend',
      () => {
        marker.parallaxZOffset = origParallaxZOffset;
        marker.marker.setLatLng(origLatLng);

        if (marker.defaultSize && marker.img) {
          marker.marker.setIcon(this._getIcon(marker.img, marker.defaultSize, 'map-label'));

          this._setOriginalOffset(marker);
        }
        // marker.marker.setOpacity(1)
        this._onMapMove(marker);
      },
      marker.marker
    )
  }

  private _getIcon(img: string, size: {w: number, h: number}, className: string = '') {
    return icon({
      iconUrl: `${environment.mediaUrl}${img}`,
      iconSize: [size.w / this._getFactor(), size.h / this._getFactor()],
      iconAnchor: [(size.w / this._getFactor()) / 2, (size.h / this._getFactor()) / 2],
      className
    })
  }

  private _calcLatLngFromOffset(marker: IMarker) {
    const offset = this._calculateOffsetFromOrigin(marker);
    const parallax = this._calculateParallaxFromOffset(marker, offset!.centerOffset);

    const containerPoint = offset!.containerPoint.add(parallax);

    return this.map.containerPointToLatLng(containerPoint);
  }

  private _updateIconOffset(marker: IMarker, offset: IOffset) {
    if (!offset || !marker.marker.getIcon()) {
      return;
    }

    const parallax = this._calculateParallaxFromOffset(marker, offset);
    const originalOffset = marker.originalOffset;
    const newOffset = originalOffset.add(parallax);

    (marker.marker as any)['_icon'].style.marginLeft = newOffset.x + 'px';
    (marker.marker as any)['_icon'].style.marginTop = newOffset.y + 'px';
  }

  private _calculateOffsetFromOrigin(marker: IMarker) {
    if (!this.map) {
      return;
    }
    const latLng = marker.marker.getLatLng();
    const markerPoint = this.map.latLngToContainerPoint(latLng);
    const centerPoint = this.map.getSize().divideBy(2);

    const deltaX = markerPoint.x - centerPoint.x;
    const deltaY = markerPoint.y - centerPoint.y;

    const offset = {x: deltaX, y: deltaY};
    const containerPoint = markerPoint.add([offset.x, offset.y]);

    return {
      containerPoint,
      centerOffset: offset as IOffset,
    };
  }

  private _calculateParallaxFromOffset(marker: IMarker, offset: IOffset) {
    const parallax = point([0, 0]);
    if (!marker.parallaxZOffset) {
      return parallax;
    }

    const z = this.map?.getZoom();
    const factor = marker.parallaxZOffset * 0.0001;
    const moveFactor = factor * Math.pow(2, z);

    parallax.x = offset.x * moveFactor;
    parallax.y = offset.y * moveFactor;

    return parallax;
  }
}

