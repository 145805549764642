import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { TuiRootModule, TuiDialogModule, TuiAlertModule, TUI_SANITIZER } from "@taiga-ui/core";
import {Component, inject, OnInit, signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import {MenuComponent} from "./components/menu/item.component";
import {MapComponent} from "./pages/map/item.component";
import {ListComponent} from "./pages/list/item.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ModalComponent} from "./components/modal/item.component";
import {AnalyticsService} from "./services/analytics.service";
import {Preferences} from "@capacitor/preferences";
import {StoreService} from './services/store.service';
import {PageLoaderComponent} from './components/page-loader/item.component';
import {WELCOME_STORAGE_KEY, WelcomeComponent} from './components/welcome-modal/item.component';

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, TuiRootModule, TuiDialogModule, TuiAlertModule, MenuComponent, MapComponent, ListComponent, ModalComponent, ListComponent, ListComponent, PageLoaderComponent, WelcomeComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [{provide: TUI_SANITIZER, useClass: NgDompurifySanitizer}],
  animations: [
    trigger('inListAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms linear', style({opacity: 1})),
      ]),
    ]),
    trigger('outListAnimation', [
      transition(':leave', [
        style({ opacity: 1}),
        animate('.3s linear', style({ opacity: 0 })),
      ]),
    ]),
    trigger('inOutMapAnimation', [
      state('open', style({ opacity: 1, transform: 'scale(1)' })),
      state('close', style({ opacity: 0.9, transform: 'scale(1.5)' })),
      transition('open => close', animate('.4s', style({ opacity: 0.9, transform: 'scale(1.5)' }))),
      transition('close => open',  animate('.4s', style({ opacity: 1, transform: 'scale(1)' })))
    ]),
  ],
})
export class AppComponent implements OnInit {
  analytics = inject(AnalyticsService);
  store = inject(StoreService)
  title = 'fl';
  isLoading = true;
  activeId = 0;

  isShowWelcome = signal(!localStorage.getItem(WELCOME_STORAGE_KEY));

  async ngOnInit() {
    this.store.loadData().subscribe(isLoad => {
      this.isLoading = false;
    })

    await this.checkFirstLaunch();

    if (Capacitor.getPlatform() !== 'web') {
      await this.handDeepLink();
    }
  }

  async checkFirstLaunch() {
    const { value } = await Preferences.get({ key: 'hasLaunched' });

    if (!value) {
      await this.analytics.trackAppInstall();
      await Preferences.set({ key: 'hasLaunched', value: 'true' });
    }

    this.analytics.trackAppLaunch();
  }

  async handDeepLink() {
    App.addListener('appUrlOpen', (data: any) => {
      const url = new URL(data.url);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');

      if (utmSource && utmMedium) {
        this.analytics.trackDeepLinkUtm('campaign', utmSource, utmMedium, utmCampaign);
        // Navigate or perform other actions based on the UTM parameters
      }
    });
  }
}
