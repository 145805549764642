import { provideAnimations } from "@angular/platform-browser/animations";
import { TuiRootModule } from "@taiga-ui/core";
import { ApplicationConfig, isDevMode, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token-interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    importProvidersFrom(
      TuiRootModule,
      LeafletModule,
    ),
    provideHttpClient(
      withInterceptors(
        [TokenInterceptor]
      )
    ),
  ],
};
