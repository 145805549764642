import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter, inject,
  Input, Output,
  ViewChild
} from '@angular/core';
import {ListCardComponent} from "../list-card/item.component";
import {TuiButtonModule} from "@taiga-ui/core";
import { StoreService } from '../../../../services/store.service';
import {environment} from '../../../../../environments/environment';
import {NgStyle} from '@angular/common';
import {Taxonomy} from '../../../../entities/Taxonomy.entity';
import {DomSanitizer} from '@angular/platform-browser';
import {RippleDirective} from '../../../../directives/ripple.directive';
import {TemplateSettingsItem} from '../../../../entities/TemplateSettings.entity';
@Component({
  selector: 'app-list-item',
  standalone: true,
  imports: [
    ListCardComponent,
    TuiButtonModule,
    NgStyle,
    RippleDirective
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class ListItemComponent implements AfterViewInit {
  @ViewChild('itemPage') itemRef!: ElementRef;

  @Input() item!: Taxonomy;
  @Input() DOMRect!: DOMRect;
  @Output() onClose = new EventEmitter<void>();

  store = inject(StoreService);
  sanitizer = inject(DomSanitizer);

  isActive = false;

  ngAfterViewInit() {
    if (this.DOMRect && this.itemEl) {
      this.itemEl.style.left = `${this.DOMRect.left}px`;
      this.itemEl.style.top = `${this.DOMRect.top}px`;
      this.itemEl.style.width = `${this.DOMRect.width}px`;
      this.itemEl.style.height = `${this.DOMRect.height}px`;
      this.itemEl.style.backgroundColor = this.item.backgroundColorFull ?? this.item.backgroundColor;
      this.itemEl.style.backgroundImage = this.item.backgroundImage?.getFull() ? `url(${this.item.backgroundImage.getFull()?.urlx3})` : '';

      setTimeout(() => {
        this.isActive = true;
      }, 100)
    }
  }

  get itemEl() : HTMLElement {
    return this.itemRef.nativeElement as HTMLElement;
  }

  textToHtml(text: string) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  close() {
    this.isActive = false;
    setTimeout(() => {
      this.onClose.emit();
    }, 400)
  }

  get currentItems() {
    return [...this.store.places(), ...this.store.posts()].filter((item) => item.category.includes(this.item.id))
  }

  onClickLink(e: Event, item: TemplateSettingsItem) {
    if (item.isDisabled) {
      e.preventDefault();
      e.stopPropagation();
    };
  }
}
