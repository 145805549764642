<tui-root>
<!--  <router-outlet></router-outlet>-->

  <app-map [@inOutMapAnimation]="activeId === 0 ? 'open' : 'close'"></app-map>

  @if (activeId === 1) {
    <app-list [@inListAnimation] [@outListAnimation]></app-list>
  }

  <app-modal></app-modal>

  <app-menu (changeActiveId)="activeId = $event"></app-menu>

  @if (isShowWelcome()) {
    <app-welcome-modal [@outListAnimation] (onClose)="isShowWelcome.set(false)"></app-welcome-modal>
  }

  @if (isLoading) {
    <app-page-loader [@outListAnimation]></app-page-loader>
  }
</tui-root>
