import {Entity} from './Entity';
import {Point} from 'leaflet';
import 'reflect-metadata';

export class Coordinates extends Entity{
  lat: number = 0;
  lng: number = 0;

  toPoint() {
    return new Point(this.lat, this.lng);
  }

  toStringData() {
    return `${this.lng}, ${this.lat}`;
  }
}
