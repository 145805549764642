import {Directive, ElementRef, Inject, Input, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Subscription, fromEvent, map, mergeMap, forkJoin, take, switchMap} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

@Directive({
  selector: '[appRipple]',
  standalone: true
})
export class RippleDirective implements OnDestroy {
  readonly subscription: Subscription;

  @Input() disable = false;

  constructor(
    @Inject(DOCUMENT) documentRef: Document,
    @Inject(ElementRef) { nativeElement }: ElementRef<HTMLElement>
  ) {
    this.subscription = fromEvent<MouseEvent>(nativeElement, "mousedown")
    .pipe(
      filter(() => !this.disable),
      map(event => {
        nativeElement.classList.add('ripple');
        const rect = nativeElement.getBoundingClientRect();

        const radius = getRadius(event.pageX - rect.x , event.pageY - rect.y, rect);
        const ripple = documentRef.createElement("div");

        ripple.className = "ripple-ink";
        ripple.setAttribute("style", getRippleStyle(event, radius, rect));

        return ripple;
      }),
      tap(ripple => nativeElement.appendChild(ripple)),
      mergeMap(ripple =>
        forkJoin([
          fromEvent(documentRef, "mouseup").pipe(take(1)),
          fromEvent(ripple, "animationend").pipe(take(1))
        ]).pipe(
          tap(() => (ripple.style.animationName = "rippleOff")),
          switchMap(() => fromEvent(ripple, "animationend").pipe(take(1))),
          tap(() => nativeElement.removeChild(ripple))
        )
      )
    )
    .subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

function getRippleStyle(
  { pageX, pageY }: MouseEvent,
  radius: number,
  rect: DOMRect
): string {
  const size = radius * 2;
  const x = pageX - rect.x - radius;
  const y = pageY - rect.y - radius;
  return `
    width: ${size}px;
    height: ${size}px;
    left: ${x}px;
    top: ${y}px;
  `;
}

function getRadius(
  x: number,
  y: number,
  { left, top, right, bottom }: DOMRect
): number {
  return Math.max(
    ...[[left, top], [left, bottom], [right, top], [right, bottom]].map(
      ([a, b]) => getDistance(x + left, y + top, a, b)
    )
  );
}

function getDistance(x1: number, y1: number, x2: number, y2: number): number {
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
}
