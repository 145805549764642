import {Entity} from './Entity';
import {Media} from './Media.entity';
import {Type} from 'class-transformer';
import 'reflect-metadata';
import {TemplateSettings} from './TemplateSettings.entity';

export class Taxonomy extends Entity {
  id!: number;

  // title on category list
  excerpt: string | null = null;

  // title on category item
  title: string| null = null;
  description: string = '';
  icon: string | null = null;
  backgroundColor: string = '#FCC572';
  backgroundColorFull: string | null = null;
  listType: 'placesSlider' | 'grid' = 'placesSlider';

  @Type(() => Media)
  cover!: Media;

  @Type(() => Media)
  backgroundImage!: Media;
  disable: boolean = false;
  size: 'half' | 'full' = 'full';
  template: 'contacts' | 'default' = 'default';

  @Type(() => TemplateSettings)
  templateSettings: TemplateSettings | null = null;
}


