import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import { version } from '../../../package.json';


export const TokenInterceptor: HttpInterceptorFn = (req, next) => {
  const platform = inject(Platform)
  const os = platform.isBrowser
    ? 'browser'
    : platform.ANDROID
      ? 'android'
      : platform.IOS ? 'ios' : 'browser';

  console.log({platform, os})
  const modifiedRequest = req.clone({
    setHeaders: {
      'Authorization': 'CP6mt0HEfsBVGzGofN8iREq1f7Fcv',
      // 'Version': `${os}_${version}`
    },
  });

  return next(modifiedRequest);
};
