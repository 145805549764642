import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {Places} from '../entities/Places.entity';
import {Post} from '../entities/Posts.entity';

export interface IModal {
  domRect: DOMRect;
  data: Places | Post;
  isCard: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }

  modal$ = new Subject<IModal | null>();
  isActive$ = new BehaviorSubject<boolean>(false);

  open(domRect: DOMRect, data: Places | Post, isCard: boolean = false) {
    this.isActive$.next(true);
    this.modal$.next({
      domRect,
      data,
      isCard
    });
  }

  close() {
    this.isActive$.next(false);
    this.modal$.next(null);
  }
}
