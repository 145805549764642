<div class="card"
     [class.small-card]="isSmall"
     appRipple
     (click)="openModal($event)"
>
  <div class="card-img">
    <img [src]="item.media[0] ? item.media[0].getFull()?.urlx1 : ''">
  </div>

  <div class="card-content">
    <div class="card-title">{{item.title}}</div>

    @if (asPlaces(); as places) {
      @if (places.addressName) {
        <div class="card-item">
          <tui-svg src="tuiIconMapPin"></tui-svg>
          <span>{{places.addressName}}</span>
        </div>
      }

      @if (places.rating) {
        <div class="card-badg">
          <tui-badge status="custom" [value]="places.rating" class="badge-rating">
            <tui-svg src="tuiIconStar"></tui-svg>
          </tui-badge>
        </div>
      }
    }
  </div>
</div>
