import {Entity} from './Entity';
import {Media} from './Media.entity';
import {Type} from 'class-transformer';
import 'reflect-metadata';

export class Post extends Entity {
  id!: number;
  title: string = '';
  description: string = '';

  @Type(() => Media)
  media: Media[] = [];
  viewType: 'placeModal' | 'linkList' = 'placeModal';
  links: {title: string; url: string}[] = [];
  category: number[] = [];
  phones: string[] = [];
}
