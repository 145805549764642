import {computed, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {plainToInstance} from 'class-transformer';
import {environment} from '../../environments/environment';
import {Places} from '../entities/Places.entity';
import {Post} from '../entities/Posts.entity';
import {Taxonomy} from '../entities/Taxonomy.entity';
import {Configuration} from '../entities/Configuration.entity';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, map} from 'rxjs';
import {Response} from '../entities/Response';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  http = inject(HttpClient);

  private _places: WritableSignal<Places[]> = signal([])
  places = computed(this._places);

  private _posts: WritableSignal<Post[]> = signal([]);
  posts = computed(this._posts);

  private _categories: WritableSignal<Taxonomy[]> = signal([]);
  categories = computed(this._categories);

  private _configurations: WritableSignal<Configuration> = signal({} as Configuration);
  configurations = computed(this._configurations);

  isLoaded = new BehaviorSubject<boolean>(false);

  constructor() { }

  loadData() {
    return this.http.get<Response>(environment.apiUrl+'/content')
      .pipe(
        map((response: Response) => {
          if (!response.status) {
            return false
          }

          this._places.set(
            plainToInstance(Places, response.result['places'])
          );

          this._posts.set(
            plainToInstance(Post, response.result['posts'])
          );

          this._categories.set(
            plainToInstance(Taxonomy, response.result['categories'])
          );

          this._configurations.set(
            plainToInstance(Configuration, response.result['configuration']) as unknown as Configuration
          );

          // console.log({
          //   places: this.places(),
          //   posts: this.posts(),
          //   categories: this.categories(),
          //   configurations: this.configurations(),
          // })

          this.isLoaded.next(true);

          return true
        })
      );
  }
}
