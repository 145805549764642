import * as i0 from '@angular/core';
import { Optional, SkipSelf, Directive, InjectionToken, Self, Inject, Input, EventEmitter, ElementRef, Component, ChangeDetectionStrategy, ContentChild, ViewChild, Output, HostListener, NgModule } from '@angular/core';
import { TuiPositionAccessor } from '@taiga-ui/core/abstract';
import * as i1$1 from '@taiga-ui/cdk';
import { TuiDestroyService, tuiTypedFromEvent, tuiGetActualTarget, tuiIsNativeKeyboardFocusable, tuiGetClosestFocusable, tuiIsNativeFocusedIn, tuiIsHTMLElement, tuiIsElementEditable, tuiIsElement, tuiAsFocusableItemAccessor, TuiActiveZoneDirective, TuiLetModule, TuiObscuredModule, TuiActiveZoneModule } from '@taiga-ui/cdk';
import * as i2 from '@taiga-ui/core/directives';
import { TuiDropdownDirective, TuiDropdownOpenDirective } from '@taiga-ui/core/directives';
import { takeUntil, map, switchMap, delay, skip, distinctUntilChanged, share } from 'rxjs/operators';
import * as i1 from 'rxjs';
import { BehaviorSubject, combineLatest, of, EMPTY, merge } from 'rxjs';
import { __decorate } from 'tslib';
import * as i3 from '@taiga-ui/core/directives/dropdown';
import { TuiDropdownHoverDirective, TuiDropdownDirective as TuiDropdownDirective$1, TuiDropdownModule, TuiDropdownOptionsDirective } from '@taiga-ui/core/directives/dropdown';
import { tuiIsEditingKey } from '@taiga-ui/core/utils/miscellaneous';
import { shouldCall } from '@tinkoff/ng-event-plugins';
import * as i5 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i6 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["wrapper"];
const _c1 = ["*"];
const _c2 = (a0, a1) => ({
  $implicit: a0,
  close: a1
});
function TuiHostedDropdownComponent_div_0_ng_template_3_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const text_r4 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r4, " ");
  }
}
function TuiHostedDropdownComponent_div_0_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6, 2);
    i0.ɵɵlistener("keydown", function TuiHostedDropdownComponent_div_0_ng_template_3_Template_div_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onKeydown($event));
    });
    i0.ɵɵtemplate(2, TuiHostedDropdownComponent_div_0_ng_template_3_div_2_Template, 2, 1, "div", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "div");
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const activeZone_r5 = i0.ɵɵreference(1);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.content)("polymorpheusOutletContext", i0.ɵɵpureFunction2(2, _c2, activeZone_r5, ctx_r1.close));
  }
}
function TuiHostedDropdownComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4, 0);
    i0.ɵɵlistener("tuiActiveZoneChange", function TuiHostedDropdownComponent_div_0_Template_div_tuiActiveZoneChange_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onActiveZone($event));
    })("tuiObscured", function TuiHostedDropdownComponent_div_0_Template_div_tuiObscured_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onHostObscured($event));
    });
    i0.ɵɵprojection(2);
    i0.ɵɵtemplate(3, TuiHostedDropdownComponent_div_0_ng_template_3_Template, 4, 5, "ng-template", 5, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const isOpen_r6 = ctx.tuiLet;
    const dropdown_r7 = i0.ɵɵreference(4);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("tuiDropdown", dropdown_r7)("tuiDropdownManual", isOpen_r6 && ctx_r1.canOpen)("tuiDropdownOpenMonitor", isOpen_r6 && ctx_r1.canOpen)("tuiDropdownSided", ctx_r1.sided)("tuiObscuredEnabled", isOpen_r6);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("polymorpheus", ctx_r1.context);
  }
}
class TuiAccessorProxyDirective extends TuiPositionAccessor {
  constructor() {
    super(...arguments);
    this.type = 'dummy';
  }
  getPosition() {
    return [0, 0];
  }
}
TuiAccessorProxyDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiAccessorProxyDirective_BaseFactory;
  return function TuiAccessorProxyDirective_Factory(t) {
    return (ɵTuiAccessorProxyDirective_BaseFactory || (ɵTuiAccessorProxyDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiAccessorProxyDirective)))(t || TuiAccessorProxyDirective);
  };
})();
TuiAccessorProxyDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiAccessorProxyDirective,
  selectors: [["", "tuiAccessorProxy", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: TuiPositionAccessor,
    multi: true,
    deps: [[new Optional(), new SkipSelf(), TuiAccessorProxyDirective], TuiAccessorProxyDirective],
    useFactory: (accessor, fallback) => accessor || fallback
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAccessorProxyDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiAccessorProxy]',
      providers: [{
        provide: TuiPositionAccessor,
        multi: true,
        deps: [[new Optional(), new SkipSelf(), TuiAccessorProxyDirective], TuiAccessorProxyDirective],
        useFactory: (accessor, fallback) => accessor || fallback
      }]
    }]
  }], null, null);
})();
const TUI_HOSTED_DROPDOWN_COMPONENT = new InjectionToken('[TUI_HOSTED_DROPDOWN_COMPONENT]');
class TuiDropdownOpenMonitorDirective {
  constructor(destroy$, hosted, dropdown, open) {
    this.hosted = hosted;
    this.open = open;
    if ((open === null || open === void 0 ? void 0 : open.dropdown) === dropdown) {
      open.tuiDropdownOpenChange.pipe(takeUntil(destroy$)).subscribe(value => {
        var _a;
        if (value) {
          (_a = hosted.nativeFocusableElement) === null || _a === void 0 ? void 0 : _a.focus();
        }
        hosted.updateOpen(value);
      });
    }
  }
  set tuiDropdownOpenMonitor(open) {
    var _a;
    (_a = this.open) === null || _a === void 0 ? void 0 : _a.update(open);
    this.hosted.updateOpen(open);
  }
}
TuiDropdownOpenMonitorDirective.ɵfac = function TuiDropdownOpenMonitorDirective_Factory(t) {
  return new (t || TuiDropdownOpenMonitorDirective)(i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TUI_HOSTED_DROPDOWN_COMPONENT), i0.ɵɵdirectiveInject(TuiDropdownDirective, 2), i0.ɵɵdirectiveInject(TuiDropdownOpenDirective, 8));
};
TuiDropdownOpenMonitorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownOpenMonitorDirective,
  selectors: [["", "tuiDropdownOpenMonitor", ""]],
  inputs: {
    tuiDropdownOpenMonitor: "tuiDropdownOpenMonitor"
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownOpenMonitorDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdownOpenMonitor]',
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_HOSTED_DROPDOWN_COMPONENT]
      }]
    }, {
      type: i2.TuiDropdownDirective,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDropdownDirective]
      }]
    }, {
      type: i2.TuiDropdownOpenDirective,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiDropdownOpenDirective]
      }]
    }];
  }, {
    tuiDropdownOpenMonitor: [{
      type: Input
    }]
  });
})();
class TuiHostedDropdownConnectorDirective {}
TuiHostedDropdownConnectorDirective.ɵfac = function TuiHostedDropdownConnectorDirective_Factory(t) {
  return new (t || TuiHostedDropdownConnectorDirective)();
};
TuiHostedDropdownConnectorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHostedDropdownConnectorDirective,
  selectors: [["", "tuiHostedDropdownHost", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHostedDropdownConnectorDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHostedDropdownHost]'
    }]
  }], null, null);
})();
function shouldClose(event) {
  var _a;
  return 'key' in event && event.key.toLowerCase() === 'escape' && this.canOpen && this.open && !((_a = this.dropdown) === null || _a === void 0 ? void 0 : _a.nextElementSibling);
}
class TuiHostedDropdownComponent {
  constructor(hover$, el) {
    this.hover$ = hover$;
    this.el = el;
    this.openChange$ = new BehaviorSubject(false);
    this.hostHover$ = combineLatest([tuiTypedFromEvent(this.el.nativeElement, 'mouseover').pipe(map(e => this.computedHost.contains(tuiGetActualTarget(e))), switchMap(visible => {
      var _a, _b;
      return of(visible).pipe(delay((visible ? (_a = this.hover$) === null || _a === void 0 ? void 0 : _a.showDelay : (_b = this.hover$) === null || _b === void 0 ? void 0 : _b.hideDelay) || 0));
    })), this.hover$ || EMPTY]).pipe(map(([visible, hovered]) => visible && hovered));
    this.sided = false;
    this.canOpen = true;
    this.open$ = merge(this.openChange$, this.hostHover$).pipe(skip(1), distinctUntilChanged(), share());
    this.focusedChange = new EventEmitter();
    /** TODO: rename in 4.0 */
    this.openChange = this.openChange$;
    this.close = () => this.updateOpen(false);
  }
  set open(open) {
    this.openChange.next(open);
  }
  get open() {
    return this.openChange.value;
  }
  get host() {
    var _a;
    return ((_a = this.dropdownHost) === null || _a === void 0 ? void 0 : _a.nativeElement) || this.el.nativeElement;
  }
  get computedHost() {
    var _a;
    return ((_a = this.dropdownHost) === null || _a === void 0 ? void 0 : _a.nativeElement) || this.nativeFocusableElement || this.el.nativeElement;
  }
  get dropdown() {
    var _a, _b;
    return (_b = (_a = this.dropdownDirective) === null || _a === void 0 ? void 0 : _a.dropdownBoxRef) === null || _b === void 0 ? void 0 : _b.location.nativeElement;
  }
  get nativeFocusableElement() {
    return tuiIsNativeKeyboardFocusable(this.host) ? this.host : tuiGetClosestFocusable({
      initial: this.host,
      root: this.el.nativeElement
    });
  }
  get focused() {
    return tuiIsNativeFocusedIn(this.host) || this.open && !!this.wrapper && tuiIsNativeFocusedIn(this.wrapper.nativeElement);
  }
  onFocusInOut() {
    this.el.nativeElement.classList.toggle('_hosted_dropdown_focused', this.focused);
  }
  onFocusIn(target) {
    if (!this.computedHost.contains(target)) {
      this.updateOpen(false);
    }
  }
  onClick(target) {
    var _a;
    if (!this.hostEditable && this.computedHost.contains(target) && !((_a = this.hover$) === null || _a === void 0 ? void 0 : _a.hovered)) {
      this.updateOpen(!this.open);
    }
  }
  onKeyDownEsc(event) {
    event.preventDefault();
    this.closeDropdown();
  }
  onArrow(event, down) {
    this.focusDropdown(event, down);
  }
  onKeydown({
    key,
    target,
    defaultPrevented
  }) {
    if (!defaultPrevented && tuiIsEditingKey(key) && this.hostEditable && tuiIsHTMLElement(target) && !tuiIsElementEditable(target)) {
      this.focusHost();
    }
  }
  onActiveZone(active) {
    this.updateFocused(active);
    if (!active) {
      this.updateOpen(false);
    }
  }
  onHostObscured(obscured) {
    if (obscured) {
      this.closeDropdown();
    }
  }
  updateOpen(open) {
    if (!open || this.canOpen) {
      this.open = open;
    }
  }
  get hostEditable() {
    return tuiIsElementEditable(this.computedHost);
  }
  focusDropdown(event, first) {
    const host = this.nativeFocusableElement;
    if (!host || !tuiIsHTMLElement(host) || !tuiIsElement(event.target) || !host.contains(event.target)) {
      return;
    }
    if (!this.wrapper || !this.open || !this.dropdown || !tuiIsHTMLElement(this.wrapper.nativeElement.nextElementSibling)) {
      this.updateOpen(true);
      if (!tuiIsElementEditable(host)) {
        event.preventDefault();
      }
      return;
    }
    const initial = first ? this.wrapper.nativeElement : this.wrapper.nativeElement.nextElementSibling;
    const focusable = tuiGetClosestFocusable({
      initial,
      root: this.wrapper.nativeElement,
      previous: !first
    });
    if (!focusable) {
      return;
    }
    focusable.focus();
    event.preventDefault();
  }
  closeDropdown() {
    if (this.focused) {
      this.focusHost();
    }
    this.updateOpen(false);
  }
  focusHost() {
    const host = this.nativeFocusableElement;
    if (host) {
      host.focus({
        preventScroll: true
      });
    }
  }
  updateFocused(focused) {
    this.focusedChange.emit(focused);
  }
}
TuiHostedDropdownComponent.ɵfac = function TuiHostedDropdownComponent_Factory(t) {
  return new (t || TuiHostedDropdownComponent)(i0.ɵɵdirectiveInject(TuiDropdownHoverDirective, 10), i0.ɵɵdirectiveInject(ElementRef));
};
TuiHostedDropdownComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiHostedDropdownComponent,
  selectors: [["tui-hosted-dropdown"]],
  contentQueries: function TuiHostedDropdownComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TuiHostedDropdownConnectorDirective, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdownHost = _t.first);
    }
  },
  viewQuery: function TuiHostedDropdownComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5, ElementRef);
      i0.ɵɵviewQuery(TuiDropdownDirective$1, 5);
      i0.ɵɵviewQuery(TuiActiveZoneDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.wrapper = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdownDirective = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.activeZone = _t.first);
    }
  },
  hostBindings: function TuiHostedDropdownComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focusin.capture.silent", function TuiHostedDropdownComponent_focusin_capture_silent_HostBindingHandler() {
        return ctx.onFocusInOut();
      })("focusout.capture.silent", function TuiHostedDropdownComponent_focusout_capture_silent_HostBindingHandler() {
        return ctx.onFocusInOut();
      })("focusin", function TuiHostedDropdownComponent_focusin_HostBindingHandler($event) {
        return ctx.onFocusIn($event.target);
      })("click", function TuiHostedDropdownComponent_click_HostBindingHandler($event) {
        return ctx.onClick($event.target);
      })("keydown.silent.capture", function TuiHostedDropdownComponent_keydown_silent_capture_HostBindingHandler($event) {
        return ctx.onKeyDownEsc($event);
      }, false, i0.ɵɵresolveDocument)("keydown.arrowDown", function TuiHostedDropdownComponent_keydown_arrowDown_HostBindingHandler($event) {
        return ctx.onArrow($event, true);
      })("keydown.arrowUp", function TuiHostedDropdownComponent_keydown_arrowUp_HostBindingHandler($event) {
        return ctx.onArrow($event, false);
      });
    }
  },
  inputs: {
    content: "content",
    sided: "sided",
    canOpen: "canOpen",
    open: "open"
  },
  outputs: {
    open$: "openChange",
    focusedChange: "focusedChange"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiHostedDropdownComponent), {
    provide: TuiAccessorProxyDirective,
    deps: [[new Optional(), new Self(), TuiPositionAccessor]],
    useFactory: position => position === null || position === void 0 ? void 0 : position[0]
  }, {
    provide: TUI_HOSTED_DROPDOWN_COMPONENT,
    useExisting: TuiHostedDropdownComponent
  }])],
  ngContentSelectors: _c1,
  decls: 2,
  vars: 3,
  consts: [["activeZone", "tuiActiveZone"], ["dropdown", "polymorpheus"], ["wrapper", ""], ["tuiAccessorProxy", "", "class", "t-wrapper", 3, "tuiDropdown", "tuiDropdownManual", "tuiDropdownOpenMonitor", "tuiDropdownSided", "tuiObscuredEnabled", "tuiActiveZoneChange", "tuiObscured", 4, "tuiLet"], ["tuiAccessorProxy", "", 1, "t-wrapper", 3, "tuiActiveZoneChange", "tuiObscured", "tuiDropdown", "tuiDropdownManual", "tuiDropdownOpenMonitor", "tuiDropdownSided", "tuiObscuredEnabled"], [3, "polymorpheus"], [1, "t-dropdown", 3, "keydown"], ["class", "t-primitive", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [1, "t-primitive"]],
  template: function TuiHostedDropdownComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, TuiHostedDropdownComponent_div_0_Template, 5, 6, "div", 3);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      let tmp_0_0;
      i0.ɵɵproperty("tuiLet", (tmp_0_0 = i0.ɵɵpipeBind1(1, 1, ctx.open$)) !== null && tmp_0_0 !== undefined ? tmp_0_0 : ctx.openChange.value);
    }
  },
  dependencies: [i1$1.TuiLetDirective, TuiAccessorProxyDirective, i3.TuiDropdownDirective, i3.TuiDropdownDriverDirective, i3.TuiDropdownPositionDirective, i3.TuiDropdownManualDirective, TuiDropdownOpenMonitorDirective, i3.TuiDropdownPositionSidedDirective, i1$1.TuiActiveZoneDirective, i1$1.TuiObscuredDirective, i5.PolymorpheusTemplate, i5.PolymorpheusOutletDirective, i6.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:inline-flex}.t-wrapper[_ngcontent-%COMP%]{border-radius:inherit;height:inherit;flex:1 1 auto;width:100%}.t-dropdown[_ngcontent-%COMP%]{height:100%}.t-primitive[_ngcontent-%COMP%]{padding:1rem}"],
  changeDetection: 0
});
__decorate([shouldCall(shouldClose)], TuiHostedDropdownComponent.prototype, "onKeyDownEsc", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHostedDropdownComponent, [{
    type: Component,
    args: [{
      selector: 'tui-hosted-dropdown',
      templateUrl: './hosted-dropdown.template.html',
      styleUrls: ['./hosted-dropdown.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiHostedDropdownComponent), {
        provide: TuiAccessorProxyDirective,
        deps: [[new Optional(), new Self(), TuiPositionAccessor]],
        useFactory: position => position === null || position === void 0 ? void 0 : position[0]
      }, {
        provide: TUI_HOSTED_DROPDOWN_COMPONENT,
        useExisting: TuiHostedDropdownComponent
      }]
    }]
  }], function () {
    return [{
      type: i3.TuiDropdownHoverDirective,
      decorators: [{
        type: Self
      }, {
        type: Optional
      }, {
        type: Inject,
        args: [TuiDropdownHoverDirective]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, {
    dropdownHost: [{
      type: ContentChild,
      args: [TuiHostedDropdownConnectorDirective, {
        read: ElementRef
      }]
    }],
    wrapper: [{
      type: ViewChild,
      args: ['wrapper', {
        read: ElementRef
      }]
    }],
    dropdownDirective: [{
      type: ViewChild,
      args: [TuiDropdownDirective$1]
    }],
    activeZone: [{
      type: ViewChild,
      args: [TuiActiveZoneDirective]
    }],
    content: [{
      type: Input
    }],
    sided: [{
      type: Input
    }],
    canOpen: [{
      type: Input
    }],
    open$: [{
      type: Output,
      args: ['openChange']
    }],
    focusedChange: [{
      type: Output
    }],
    open: [{
      type: Input
    }],
    onFocusInOut: [{
      type: HostListener,
      args: ['focusin.capture.silent']
    }, {
      type: HostListener,
      args: ['focusout.capture.silent']
    }],
    onFocusIn: [{
      type: HostListener,
      args: ['focusin', ['$event.target']]
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event.target']]
    }],
    onKeyDownEsc: [{
      type: HostListener,
      args: ['document:keydown.silent.capture', ['$event']]
    }],
    onArrow: [{
      type: HostListener,
      args: ['keydown.arrowDown', ['$event', 'true']]
    }, {
      type: HostListener,
      args: ['keydown.arrowUp', ['$event', 'false']]
    }]
  });
})();
class TuiHostedDropdownModule {}
TuiHostedDropdownModule.ɵfac = function TuiHostedDropdownModule_Factory(t) {
  return new (t || TuiHostedDropdownModule)();
};
TuiHostedDropdownModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiHostedDropdownModule
});
TuiHostedDropdownModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule, TuiLetModule, TuiObscuredModule, TuiActiveZoneModule, TuiDropdownModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHostedDropdownModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiLetModule, TuiObscuredModule, TuiActiveZoneModule, TuiDropdownModule],
      declarations: [TuiAccessorProxyDirective, TuiDropdownOpenMonitorDirective, TuiHostedDropdownComponent, TuiHostedDropdownConnectorDirective],
      exports: [TuiHostedDropdownComponent, TuiHostedDropdownConnectorDirective, TuiDropdownOptionsDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiAccessorProxyDirective, TuiDropdownOpenMonitorDirective, TuiHostedDropdownComponent, TuiHostedDropdownConnectorDirective, TuiHostedDropdownModule };
