import { Injectable, Inject } from '@angular/core';
import '@capacitor-community/firebase-analytics'

import {Capacitor, Plugins} from '@capacitor/core';
import {environment} from "../../environments/environment";
const {FirebaseAnalytics, Device} = Plugins;

interface ModalElementClick {
  type?: string;
  value: string;
  link?: string
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor() {
    this.initFb();
  }

  async initFb() {
    if (Capacitor.getPlatform() === 'web') {
      // console.log('web');
      try {
        await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
        console.log('Firebase initialized');
      } catch (error) {
        console.error('Firebase initialization failed:', error);
      }
    }
  }

  async trackOpenPlaceModal(value: string, type: string) {
    const event = {
      value
    };
    await FirebaseAnalytics.logEvent({name: type, params: event});
  }

  async trackModalElementClick(type: string, value: string, link?: string) {
    const event: ModalElementClick = {
      type,
      value
    };

    if (link) {
      event.link = link;
    }

    await FirebaseAnalytics.logEvent({name: 'modal_element_click', params: event});
  }

  async trackOpenCategory(value: string) {
    const event = {
      value
    };

    await FirebaseAnalytics.logEvent({name: 'open_category', params: event});
  }

  async trackAppInstall() {
    const event = {
      category: 'Application',
      action: 'Install',
      label: 'First Launch'
    };

    await FirebaseAnalytics.logEvent({name: 'app_install', params: event});
  }

  async trackAppLaunch() {
    const event = {
      category: 'Application',
      action: 'Launch',
      label: 'Subsequent Launch'
    };

    await FirebaseAnalytics.logEvent({name: 'app_launch', params: event});
  }

  async trackDeepLinkUtm(name: string, utmSource: string, utmMedium: string, utmCampaign: string | null) {
    FirebaseAnalytics.logEvent({
      name: name,
      params: {
        source: utmSource,
        medium: utmMedium,
        campaign: utmCampaign,
      },
    });
  }
}
