import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, HostBinding, Directive, Inject, ElementRef, Renderer2, ChangeDetectorRef, NgZone, ViewChild, Input, Output, HostListener, NgModule } from '@angular/core';
import * as i1$1 from '@taiga-ui/cdk';
import { tuiCreateToken, tuiProvideOptions, TuiDestroyService, TuiHoveredService, tuiTypedFromEvent, tuiIsCurrentTarget, tuiZonefree, tuiPx, TuiResizeModule, TuiLetModule } from '@taiga-ui/cdk';
import * as i3 from '@taiga-ui/core';
import { TuiHintComponent, TuiPositionService, tuiPositionAccessorFor, TuiHintPositionDirective, tuiRectAccessorFor, TuiHintDirective, tuiFadeIn, TuiPositionAccessor, tuiAsPositionAccessor, TUI_HINT_COMPONENT, TuiHintModule } from '@taiga-ui/core';
import { BehaviorSubject, Subject, of, timer } from 'rxjs';
import { distinctUntilChanged, startWith, pairwise, switchMap, filter, map } from 'rxjs/operators';
import * as i1 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
function TuiLineClampBoxComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(text_r1);
  }
}
function TuiLineClampComponent_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r3 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r3, " ");
  }
}
function TuiLineClampComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("mouseenter", function TuiLineClampComponent_div_0_Template_div_mouseenter_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.updateView());
    })("tuiResize", function TuiLineClampComponent_div_0_Template_div_tuiResize_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.updateView());
    });
    i0.ɵɵtemplate(1, TuiLineClampComponent_div_0_ng_container_1_Template, 2, 1, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const lineClamp_r4 = ctx.tuiLet;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("-webkit-line-clamp", lineClamp_r4)("word-break", (lineClamp_r4 || 0) > 1 ? "break-word" : "break-all");
    i0.ɵɵproperty("tuiHint", ctx_r1.computedContent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.content);
  }
}
const TUI_LINE_CLAMP_DEFAULT_OPTIONS = {
  showHint: true
};
/**
 * Default parameters for LineClamp component
 */
const TUI_LINE_CLAMP_OPTIONS = tuiCreateToken(TUI_LINE_CLAMP_DEFAULT_OPTIONS);
function tuiLineClampOptionsProvider(options) {
  return tuiProvideOptions(TUI_LINE_CLAMP_OPTIONS, options, TUI_LINE_CLAMP_DEFAULT_OPTIONS);
}
class TuiLineClampBoxComponent extends TuiHintComponent {
  get width() {
    return this.accessor.getClientRect().width;
  }
}
TuiLineClampBoxComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiLineClampBoxComponent_BaseFactory;
  return function TuiLineClampBoxComponent_Factory(t) {
    return (ɵTuiLineClampBoxComponent_BaseFactory || (ɵTuiLineClampBoxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiLineClampBoxComponent)))(t || TuiLineClampBoxComponent);
  };
})();
TuiLineClampBoxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiLineClampBoxComponent,
  selectors: [["tui-line-clamp-box"]],
  hostVars: 2,
  hostBindings: function TuiLineClampBoxComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵstyleProp("min-width", ctx.width, "px");
    }
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService, TuiPositionService, TuiHoveredService, tuiPositionAccessorFor('hint', TuiHintPositionDirective), tuiRectAccessorFor('hint', TuiHintDirective)]), i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [[4, "polymorpheusOutlet"]],
  template: function TuiLineClampBoxComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiLineClampBoxComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("polymorpheusOutlet", ctx.content);
    }
  },
  dependencies: [i1.PolymorpheusOutletDirective],
  styles: ["[_nghost-%COMP%]{position:absolute;box-shadow:var(--tui-shadow-dropdown);width:-webkit-min-content;width:min-content;padding:.75rem 1rem;margin-left:calc(-1px - 1rem);margin-top:calc(-1px - .75rem);border-radius:var(--tui-radius-l);box-sizing:content-box;border:1px solid var(--tui-base-03);background:var(--tui-base-01);color:var(--tui-text-01);word-wrap:break-word}"],
  data: {
    animation: [tuiFadeIn]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiLineClampBoxComponent, [{
    type: Component,
    args: [{
      selector: 'tui-line-clamp-box',
      template: `
        <ng-container *polymorpheusOutlet="content as text">{{ text }}</ng-container>
    `,
      styleUrls: ['./line-clamp-box.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TuiDestroyService, TuiPositionService, TuiHoveredService, tuiPositionAccessorFor('hint', TuiHintPositionDirective), tuiRectAccessorFor('hint', TuiHintDirective)],
      animations: [tuiFadeIn]
    }]
  }], null, {
    width: [{
      type: HostBinding,
      args: ['style.minWidth.px']
    }]
  });
})();
class TuiLineClampPositionDirective extends TuiPositionAccessor {
  constructor(accessor) {
    super();
    this.accessor = accessor;
    this.type = 'hint';
  }
  getPosition() {
    const {
      top,
      left
    } = this.accessor.getClientRect();
    return [top, left];
  }
}
TuiLineClampPositionDirective.ɵfac = function TuiLineClampPositionDirective_Factory(t) {
  return new (t || TuiLineClampPositionDirective)(i0.ɵɵdirectiveInject(TuiHintDirective));
};
TuiLineClampPositionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiLineClampPositionDirective,
  selectors: [["", "tuiLineClamp", ""]],
  features: [i0.ɵɵProvidersFeature([tuiAsPositionAccessor(TuiLineClampPositionDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiLineClampPositionDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiLineClamp]',
      providers: [tuiAsPositionAccessor(TuiLineClampPositionDirective)]
    }]
  }], function () {
    return [{
      type: i3.TuiRectAccessor,
      decorators: [{
        type: Inject,
        args: [TuiHintDirective]
      }]
    }];
  }, null);
})();
class TuiLineClampComponent {
  constructor(el, renderer, cd, zone, options) {
    this.el = el;
    this.renderer = renderer;
    this.cd = cd;
    this.zone = zone;
    this.options = options;
    this.linesLimit$ = new BehaviorSubject(1);
    this.isOverflown$ = new Subject();
    this.initialized = false;
    this.lineHeight = 24;
    this.overflownChange = this.isOverflown$.pipe(distinctUntilChanged());
    this.lineClamp$ = this.linesLimit$.pipe(startWith(1), pairwise(), switchMap(([prev, next]) => next >= prev ? of(next) : tuiTypedFromEvent(this.el.nativeElement, 'transitionend').pipe(filter(tuiIsCurrentTarget), map(() => next))));
    this.skipInitialTransition();
  }
  set linesLimit(linesLimit) {
    this.linesLimit$.next(linesLimit);
  }
  get overflown() {
    if (!this.outlet) {
      return false;
    }
    const {
      scrollHeight,
      scrollWidth
    } = this.outlet.nativeElement;
    const {
      clientHeight,
      clientWidth
    } = this.el.nativeElement;
    // 4px buffer for IE/Edge incorrectly rounding scrollHeight
    return scrollHeight - clientHeight > 4 || scrollWidth - clientWidth > 0;
  }
  get computedContent() {
    return this.options.showHint && this.overflown ? this.content : '';
  }
  updateView() {
    this.cd.detectChanges();
  }
  ngDoCheck() {
    this.update();
    this.isOverflown$.next(this.overflown);
  }
  ngAfterViewInit() {
    this.initialized = true;
  }
  skipInitialTransition() {
    timer(0).pipe(tuiZonefree(this.zone)).subscribe(() => {
      this.renderer.addClass(this.el.nativeElement, '_initialized');
      this.cd.detectChanges();
    });
  }
  update() {
    if (this.outlet) {
      this.renderer.setStyle(this.el.nativeElement, 'height', tuiPx(this.outlet.nativeElement.scrollHeight + 4));
    }
    if (this.initialized) {
      this.renderer.setStyle(this.el.nativeElement, 'max-height', tuiPx(this.lineHeight * this.linesLimit$.value));
    }
  }
}
TuiLineClampComponent.ɵfac = function TuiLineClampComponent_Factory(t) {
  return new (t || TuiLineClampComponent)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(Renderer2), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(TUI_LINE_CLAMP_OPTIONS));
};
TuiLineClampComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiLineClampComponent,
  selectors: [["tui-line-clamp"]],
  viewQuery: function TuiLineClampComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiHintDirective, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.outlet = _t.first);
    }
  },
  hostBindings: function TuiLineClampComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("transitionend", function TuiLineClampComponent_transitionend_HostBindingHandler() {
        return ctx.updateView();
      });
    }
  },
  inputs: {
    linesLimit: "linesLimit",
    lineHeight: "lineHeight",
    content: "content"
  },
  outputs: {
    overflownChange: "overflownChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_HINT_COMPONENT,
    useValue: TuiLineClampBoxComponent
  }])],
  decls: 2,
  vars: 3,
  consts: [["tuiLineClamp", "", "class", "t-wrapper", 3, "-webkit-line-clamp", "word-break", "tuiHint", "mouseenter", "tuiResize", 4, "tuiLet"], ["tuiLineClamp", "", 1, "t-wrapper", 3, "mouseenter", "tuiResize", "tuiHint"], [4, "polymorpheusOutlet"]],
  template: function TuiLineClampComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiLineClampComponent_div_0_Template, 2, 6, "div", 0);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(1, 1, ctx.lineClamp$));
    }
  },
  dependencies: [i1$1.TuiLetDirective, TuiLineClampPositionDirective, i3.TuiHintDirective, i3.TuiHintDriverDirective, i3.TuiHintHoverDirective, i3.TuiHintPositionDirective, i1$1.TuiResizeDirective, i1.PolymorpheusOutletDirective, i5.AsyncPipe],
  styles: ["[_nghost-%COMP%]{position:relative;display:block;overflow:hidden}._initialized[_nghost-%COMP%]{transition-property:max-height;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out}.t-wrapper[_ngcontent-%COMP%]{display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;overflow-wrap:anywhere}.t-wrapper[_ngcontent-%COMP%]    >*{white-space:initial}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiLineClampComponent, [{
    type: Component,
    args: [{
      selector: 'tui-line-clamp',
      templateUrl: './line-clamp.template.html',
      styleUrls: ['./line-clamp.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: TUI_HINT_COMPONENT,
        useValue: TuiLineClampBoxComponent
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.Renderer2,
      decorators: [{
        type: Inject,
        args: [Renderer2]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_LINE_CLAMP_OPTIONS]
      }]
    }];
  }, {
    outlet: [{
      type: ViewChild,
      args: [TuiHintDirective, {
        read: ElementRef
      }]
    }],
    linesLimit: [{
      type: Input
    }],
    lineHeight: [{
      type: Input
    }],
    content: [{
      type: Input
    }],
    overflownChange: [{
      type: Output
    }],
    updateView: [{
      type: HostListener,
      args: ['transitionend']
    }]
  });
})();
class TuiLineClampModule {}
TuiLineClampModule.ɵfac = function TuiLineClampModule_Factory(t) {
  return new (t || TuiLineClampModule)();
};
TuiLineClampModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiLineClampModule
});
TuiLineClampModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule, TuiHintModule, TuiResizeModule, TuiLetModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiLineClampModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiHintModule, TuiResizeModule, TuiLetModule],
      declarations: [TuiLineClampComponent, TuiLineClampBoxComponent, TuiLineClampPositionDirective],
      exports: [TuiLineClampComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_LINE_CLAMP_DEFAULT_OPTIONS, TUI_LINE_CLAMP_OPTIONS, TuiLineClampBoxComponent, TuiLineClampComponent, TuiLineClampModule, tuiLineClampOptionsProvider };
