import {Entity} from './Entity';
import 'reflect-metadata';
import {Type} from 'class-transformer';


export class TemplateSettings extends Entity {
  @Type(() => TemplateSettingsItem)
  items: TemplateSettingsItem[] = []
}

export class TemplateSettingsItem extends Entity {
  image: string = '';
  title: string = '';
  link: string = '';
  isDisabled: boolean = true;
}
