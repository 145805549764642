<div class="item"
     [class.active]="isActive"
     #itemPage
     [ngStyle]="{
      'background-color':  item.backgroundColorFull ?? item.backgroundColor
     }"
>
  <div [class]="'item-wrap ' + item.template " [class.support]="item.excerpt === 'Support information'">
    <button
      appearance="item-close"
      tuiIconButton
      type="button"
      icon="tuiIconArrowLeftLarge"
      size="m"
      class="item-close"
      appRipple
      (click)="close()"
    ></button>

    <div [class]="'item-title ' " [innerHTML]="item.title"></div>
    <div class="item-descr" [innerHTML]="textToHtml(item.description)"></div>

    @if (item.template === 'contacts') {
      <div class="item-contacts">
        @for (item of item.templateSettings?.items; track item.link) {
          <a class="item-contacts-block"
             [class.disabled]="item.isDisabled"
             [href]="item.link"
             target="_blank"
             (click)="onClickLink($event, item)"
          >
            <div class="item-contacts-block_img">
              <img [src]="item.image">
            </div>
            <div class="item-contacts-block_text">{{item.title}}</div>
          </a>
        }
      </div>
    } @else {
      @if (item.listType === 'grid') {
        <div class="item-rows">
          @for (item of currentItems; track item.title) {
            <app-list-card [item]="item" [isSmall]="true"></app-list-card>
          }
        </div>
      } @else {
        <div class="item-cards" [style.grid-template-columns]="'repeat('+ currentItems.length +', 230px)'">
          @for (item of currentItems; track item.title) {
            <app-list-card [item]="item"></app-list-card>
          }
        </div>
      }
    }
  </div>
</div>
