import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {DomSanitizer} from '@angular/platform-browser';
import {RippleDirective} from '../../directives/ripple.directive';

export const WELCOME_STORAGE_KEY = 'welcome-modal'

@Component({
  selector: 'app-welcome-modal',
  standalone: true,
  imports: [
    RippleDirective
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class WelcomeComponent implements OnInit {
  store = inject(StoreService);
  sanitizer = inject(DomSanitizer)

  @Output() onClose = new EventEmitter<void>();

  ngOnInit() {
    localStorage.setItem(WELCOME_STORAGE_KEY, '1');
  }

  get welcomeText() {
    return this.sanitizer.bypassSecurityTrustHtml(this.store.configurations().startPopup?.text ?? '')
  }
}
