import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {TuiSvgModule} from "@taiga-ui/core";
import {ModalService} from "../../services/modal.service";
import {AsyncPipe} from "@angular/common";
import {TuiDestroyService} from "@taiga-ui/cdk";
import {RippleDirective} from '../../directives/ripple.directive';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [TuiSvgModule, AsyncPipe, RippleDirective],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  providers: [TuiDestroyService]
})
export class MenuComponent implements OnInit {

  modalService = inject(ModalService);

  isOpen = false;

  @Output() changeActiveId= new EventEmitter<number>();
  activeIdx = 0;

  ngOnInit() {
  }

  change(id: number) {
    if (id === this.activeIdx) {
      return;
    }

    this.modalService.close();
    this.activeIdx = id;
    this.changeActiveId.emit(this.activeIdx);
  }
}
