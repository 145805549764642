import {Component, inject} from '@angular/core';
import {TuiScrollbarModule, TuiSvgModule} from "@taiga-ui/core";
import {ListItemComponent} from "./components/list-item/item.component";
import {NgStyle} from "@angular/common";
import {UrlPipe} from '../../pipes/url.pipe';
import {AnalyticsService} from "../../services/analytics.service";
import {StoreService} from '../../services/store.service';
import {Taxonomy} from '../../entities/Taxonomy.entity';
import {RippleDirective} from '../../directives/ripple.directive';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [
    TuiScrollbarModule,
    ListItemComponent,
    NgStyle,
    UrlPipe,
    TuiSvgModule,
    RippleDirective
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class ListComponent {

  store = inject(StoreService);

  analytics = inject(AnalyticsService);

  isOpen = false;
  domRect?: DOMRect;
  selectedItem?: Taxonomy;

  openItem(event: MouseEvent, item: Taxonomy) {
    if (item.disable) {
      return;
    }
    this.domRect = (event.target as HTMLElement).getBoundingClientRect();
    this.selectedItem = item;
    this.isOpen = true;
    // Отслеживаем открытие категории
    this.analytics.trackOpenCategory(
      `${item.id.toString()} - ${item.title}`,
    );
  }

  closeItem() {
    this.domRect = undefined;
    this.selectedItem = undefined;
    this.isOpen = false;
  }

  imageBg(item: Taxonomy) {
    return item?.cover?.getFull() && `url(${item.cover.getFull()?.urlx3})`
  }
}
