<div class="menu"
     [class.active-list]="activeIdx === 1"
>
  <button [class.active]="activeIdx === 0" (click)="change(0)">
    <tui-svg src="tuiIconMapPinLarge"></tui-svg>
    <span>Map</span>
  </button>
  <button [class.active]="activeIdx === 1"  (click)="change(1)">
    <tui-svg src="tuiIconListLarge"></tui-svg>
    <span>List</span>
  </button>
</div>
