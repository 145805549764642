<tui-scrollbar>
  <div class="list">
    <div class="list-row">
      @for (item of store.categories(); track item.title) {
        <div
          [class]="'list-item ' + item.size +' ' + item.template"
          [ngStyle]="{
            'background-image': imageBg(item),
            'background-color': item.backgroundColor,
            'animation-delay': [0, 1].includes($index) ? '.2s' :  $index * 0.15 +'s',
          }"
          appRipple
          [disable]="item.disable"
          (click)="openItem($event, item)">
          @if (item.icon) {
            <div class="list-item-icon">
              <tui-svg [src]="item.icon"></tui-svg>
            </div>
          }
         <span>
            {{ item.excerpt }}
         </span>
        </div>
      }
    </div>
  </div>
</tui-scrollbar>


@if (isOpen) {
  <app-list-item [DOMRect]="domRect!" [item]="selectedItem!" (onClose)="closeItem()"></app-list-item>
}
