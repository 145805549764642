import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { ElementRef, Directive, Inject, Output, Component, ChangeDetectionStrategy, HostBinding, NgModule } from '@angular/core';
import { shouldCall } from '@tinkoff/ng-event-plugins';
import { MutationObserverService, MUTATION_OBSERVER_INIT } from '@ng-web-apis/mutation-observer';
import { ResizeObserverService } from '@ng-web-apis/resize-observer';
import * as i1 from 'rxjs';
import { merge } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
const _c0 = ["*"];
class TuiElasticContainerDirective {
  constructor(el, resize$, mutation$) {
    this.el = el;
    this.resize$ = resize$;
    this.mutation$ = mutation$;
    this.tuiElasticContainer = merge(this.resize$, this.mutation$).pipe(debounceTime(0), map(() => this.el.nativeElement.clientHeight - 1), distinctUntilChanged());
  }
}
TuiElasticContainerDirective.ɵfac = function TuiElasticContainerDirective_Factory(t) {
  return new (t || TuiElasticContainerDirective)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(ResizeObserverService), i0.ɵɵdirectiveInject(MutationObserverService));
};
TuiElasticContainerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiElasticContainerDirective,
  selectors: [["", "tuiElasticContainer", ""]],
  outputs: {
    tuiElasticContainer: "tuiElasticContainer"
  },
  features: [i0.ɵɵProvidersFeature([ResizeObserverService, MutationObserverService, {
    provide: MUTATION_OBSERVER_INIT,
    useValue: {
      childList: true,
      characterData: true,
      subtree: true
    }
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiElasticContainerDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiElasticContainer]',
      providers: [ResizeObserverService, MutationObserverService, {
        provide: MUTATION_OBSERVER_INIT,
        useValue: {
          childList: true,
          characterData: true,
          subtree: true
        }
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ResizeObserverService]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [MutationObserverService]
      }]
    }];
  }, {
    tuiElasticContainer: [{
      type: Output
    }]
  });
})();
class TuiElasticContainerComponent {
  constructor() {
    this.height = NaN;
    this.transitions = 0;
  }
  onAnimation(_name, count) {
    this.transitions += count;
  }
}
TuiElasticContainerComponent.ɵfac = function TuiElasticContainerComponent_Factory(t) {
  return new (t || TuiElasticContainerComponent)();
};
TuiElasticContainerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiElasticContainerComponent,
  selectors: [["tui-elastic-container"]],
  hostVars: 4,
  hostBindings: function TuiElasticContainerComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵstyleProp("height", ctx.height, "px");
      i0.ɵɵclassProp("_transitioning", ctx.transitions);
    }
  },
  ngContentSelectors: _c0,
  decls: 2,
  vars: 0,
  consts: [[1, "t-wrapper", 3, "transitioncancel.silent", "transitionend.silent", "transitionstart.silent", "tuiElasticContainer"]],
  template: function TuiElasticContainerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("transitioncancel.silent", function TuiElasticContainerComponent_Template_div_transitioncancel_silent_0_listener($event) {
        return ctx.onAnimation($event.propertyName, -1);
      })("transitionend.silent", function TuiElasticContainerComponent_Template_div_transitionend_silent_0_listener($event) {
        return ctx.onAnimation($event.propertyName, -1);
      })("transitionstart.silent", function TuiElasticContainerComponent_Template_div_transitionstart_silent_0_listener($event) {
        return ctx.onAnimation($event.propertyName, 1);
      })("tuiElasticContainer", function TuiElasticContainerComponent_Template_div_tuiElasticContainer_0_listener($event) {
        return ctx.height = $event;
      });
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
  },
  dependencies: [TuiElasticContainerDirective],
  styles: ["[_nghost-%COMP%]{transition-property:height;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;display:block;overflow:hidden}._transitioning[_nghost-%COMP%]{height:auto!important}.t-wrapper[_ngcontent-%COMP%]{padding-top:1px;margin-top:-1px}"],
  changeDetection: 0
});
__decorate([shouldCall(name => name === 'height')], TuiElasticContainerComponent.prototype, "onAnimation", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiElasticContainerComponent, [{
    type: Component,
    args: [{
      selector: 'tui-elastic-container',
      templateUrl: './elastic-container.component.html',
      styleUrls: ['./elastic-container.component.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    height: [{
      type: HostBinding,
      args: ['style.height.px']
    }],
    transitions: [{
      type: HostBinding,
      args: ['class._transitioning']
    }],
    onAnimation: []
  });
})();
class TuiElasticContainerModule {}
TuiElasticContainerModule.ɵfac = function TuiElasticContainerModule_Factory(t) {
  return new (t || TuiElasticContainerModule)();
};
TuiElasticContainerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiElasticContainerModule
});
TuiElasticContainerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiElasticContainerModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiElasticContainerComponent, TuiElasticContainerDirective],
      exports: [TuiElasticContainerComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiElasticContainerComponent, TuiElasticContainerDirective, TuiElasticContainerModule };
