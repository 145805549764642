import {Component, inject, Input} from '@angular/core';
import {TuiSvgModule} from "@taiga-ui/core";
import {TuiBadgeModule} from "@taiga-ui/kit";
import {ModalService} from "../../../../services/modal.service";
import {UrlPipe} from '../../../../pipes/url.pipe';
import {Places} from '../../../../entities/Places.entity';
import {Post} from '../../../../entities/Posts.entity';
import {RippleDirective} from '../../../../directives/ripple.directive';

@Component({
  selector: 'app-list-card',
  standalone: true,
  imports: [
    TuiSvgModule,
    TuiBadgeModule,
    UrlPipe,
    RippleDirective
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class ListCardComponent  {
  @Input() item!: Places | Post;
  @Input() isSmall!: boolean;

  modalService = inject(ModalService);

  openModal(event: MouseEvent) {
    this.modalService.open(
      (event.target as HTMLElement).getBoundingClientRect(),
      this.item,
      true
    )
  }

  asPlaces() {
    return this.item instanceof Places ? this.item as Places : undefined;
  }
}
