export const environment = {
  production: false,
  mediaUrl: 'https://media.wow-atlas.com',
  firebaseConfig: {
    apiKey: "AIzaSyDwsuiykhwe8SqU8MJ9TYGqoW_MtaqSM-0",
    authDomain: "wow--atlas.firebaseapp.com",
    projectId: "wow--atlas",
    storageBucket: "wow--atlas.appspot.com",
    messagingSenderId: "978487037170",
    appId: "1:978487037170:web:e6d4fef876426924da546b",
    measurementId: "G-T3PFJ2GXVR"
  },
  apiUrl: 'https://dev.api.atlas.staging.lobsterlab.io',
  clid: 'ak240524',
  apikey: 'ycytISAchCFuArNNvdLWHjEfPPDIydULb'
};
