import {Entity} from './Entity';
import {Type} from 'class-transformer';
import 'reflect-metadata';

export class Configuration extends Entity {
  mapTitle: string | null = null;

  @Type(() => ConfigurationStartPopup)
  startPopup: ConfigurationStartPopup | null = null;
}

export class ConfigurationStartPopup extends Entity {
  text: string | null = null;
  buttonText: string | null = null;
  buttonLink: string | null = null;
  active: boolean = true;
}
