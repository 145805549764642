export interface IMapLabel {
  img: string;
  latLng: [number, number];
}

export const MAP_LABELS: IMapLabel[] = [
  {
    img: '/assets/images/labels/solnechegorsk.webp',
    latLng: [-88, 84]
  },
  {
    img: '/assets/images/labels/dmitrov.webp',
    latLng: [-10, 245]
  },
  {
    img: '/assets/images/labels/sergiev-posad.webp',
    latLng: [-43, 345]
  },
  {
    img: '/assets/images/labels/pushkino.webp',
    latLng: [-162, 270]
  },
  {
    img: '/assets/images/labels/orehovo-zuevo.webp',
    latLng: [-230, 478]
  },
  {
    img: '/assets/images/labels/ramenskoe.webp',
    latLng: [-320, 394]
  },
  {
    img: '/assets/images/labels/voskresensk.webp',
    latLng: [-400, 474]
  },
  // {
  //   img: '/assets/images/labels/kolomna.webp',
  //   latLng: [-492 , 460]
  // },
  // {
  //   img: '/assets/images/labels/mihnevo.webp',
  //   latLng: [-486, 340]
  // },
  // {
  //   img: '/assets/images/labels/chechov.webp',
  //   latLng: [-486, 198]
  // },
  {
    img: '/assets/images/labels/podolsk.webp',
    latLng: [-363, 216]
  },
  {
    img: '/assets/images/labels/naro-fominsk.webp',
    latLng: [-379, 38]
  },
  {
    img: '/assets/images/labels/odincovo.webp',
    latLng: [-280, 144]
  },
  {
    img: '/assets/images/labels/moscow.webp',
    latLng: [-307, 244]
  },
  {
    img: '/assets/images/labels/moscow-region.webp',
    latLng: [-15, 62]
  },
]
