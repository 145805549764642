import {Entity} from './Entity';
import {MediaSizes} from './MediaSizes.entity';
import {Type} from 'class-transformer';
import 'reflect-metadata';

export class Media extends Entity {
  id!: number;
  original!: string;
  type: 'image' | 'video' = 'image';

  @Type(() => MediaSizes)
  sizes: MediaSizes[] = [];

  getFull() {
    return this.sizes.find(item => item.type === 'full');
  }

  getList() {
    return this.sizes.find(item => item.type === 'list');
  }

  getSmall() {
    return this.sizes.find(item => item.type === 'small');
  }

  getMedium() {
    return this.sizes.find(item => item.type === 'medium');
  }
}
